import { AnimationOnScroll } from "react-animation-on-scroll"
import React from "react"

import { Container, Table, Card } from "react-bootstrap"
import { Link } from "gatsby"
import AnilKumar from "../images/newDesign/carousalImages/Anil_kumar.png"
import Sachin from "../images/newDesign/carousalImages/UWD_CEO_SachinGol.png"
import Dinesh from "../images/newDesign/carousalImages/2.jpg"
import Mohan from "../images/newDesign/carousalImages/3.jpg"
import Purnamita from "../images/newDesign/carousalImages/4.jpg"
import Carousel from "react-multi-carousel"

export default function PressRelease() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const advisors = [
    {
      url: Mohan,
      name: "Mr. Mohan Soni",
      designation: "Founder Partner TAMS & CO LLP",
      linkedin:
        "https://www.linkedin.com/in/mohan-soni-2a81ba103/?originalSubdomain=in",
    },
    {
      url: Dinesh,
      name: "Mr. Dinesh Agrawal",
      designation: "Principal Consultant Consocia Advisory",
      linkedin: "https://www.linkedin.com/in/dinagrawal/?originalSubdomain=in",
    },
    {
      url: Purnamita,
      name: "Prof. Purnamita Dasgupta",
      designation:
        "Chair, Environmental Economics and Head, Environmental and Resource Economics Unit Institute of Economic Growth",
      // linkedin: "",
    },
    {
      url: Sachin,
      name: "Mr. Sachin Golwalkar ",
      designation: "CEO, United Way Delhi",
      linkedin:
        "https://www.linkedin.com/in/sachin-golwalkar-4244144/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=in",
    },
  ]
  return (
    <>
      <div className="mt-5 pt-5 mb-5 pt-5 px-5 container-custom">
        <h1 className="heading mb-5">Training Program</h1>
        <div className="row">
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
            style={{
              backgroundColor: "#f7f4e7",
            }}
          >
            <div class="card border-0 p-2 bg-transparent">
              <div class="card-body">
                <h5 class="card-title fw-bold h2 heading">
                  Environmental & Sustainability
                </h5>
                <p class="card-text comman-para" align="justify">
                  Understanding the impact of business operations on the
                  environment, climate change, resource conservation, pollution
                  control and renewable energy.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
            style={{
              backgroundColor: "#f7f4e7",
            }}
          >
            <div class="card border-0 p-2 bg-transparent">
              <div class="card-body">
                <h5 class="card-title fw-bold h3 heading">Social Factors</h5>
                <p class="card-text comman-para" align="justify">
                  Examining the social impact of organisations, including
                  diversity, human rights, employee and community engagement and
                  social impact assessment.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
            style={{
              backgroundColor: "#f7f4e7",
            }}
          >
            <div class="card border-0 p-2 bg-transparent">
              <div class="card-body">
                <h5 class="card-title fw-bold h2 heading">
                  Governance Factors
                </h5>
                <p class="card-text comman-para" align="justify">
                  Exploring NGRBC principles of good corporate governance,
                  ethical practices, transparency, accountability and the
                  board's role in overseeing ESG performance.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
            style={{
              backgroundColor: "#f7f4e7",
            }}
          >
            <div class="card border-0 p-2 bg-transparent">
              <div class="card-body">
                <h5 class="card-title fw-bold h2 heading">
                  ESG Reporting & BRSR Disclosure
                </h5>
                <p class="card-text comman-para" align="justify">
                  Understanding of Indian ESG reporting framework, BRSR
                  guidelines and illustrative cases to effectively communicate
                  ESG performance to stakeholders.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" pt-5 mb-5">
          <h1 className="heading mb-5" style={{ fontSize: "35px" }}>
            OUR Experts
          </h1>
          <Carousel
            responsive={responsive}
            arrows={true}
            infinite={true}
            autoPlay={true}
          >
            {advisors.map(data => {
              return (
                <div className="m-3">
                  <Card className="speaker-cards">
                    <Card.Img variant="top" src={data.url} />
                    <Card.Body>
                      <div className="row">
                        <div className="col-9">
                          <div className="card-heading fw-bold">
                            {data.name}
                          </div>
                        </div>
                        {data.linkedin && (
                          <div className="col-3 text-end">
                            <a target="_blank" href={data.linkedin}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                class="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </a>
                          </div>
                        )}
                      </div>

                      <p className="designation-text pt-2">
                        {data.designation}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              )
            })}
          </Carousel>
        </div>
        <div className="comman-para">
          ESG Orientation Program 2023: Fostering Sustainability and Cultivating
          Collaborative Learning
          <div>India Habitat Centre, New Delhi</div>
          <div>August 4, 2023</div>
          <br />
          <br />
          The ESG Orientation Program, held at the India Habitat Centre on
          August 4, 2023, began with a compelling opening speech by Mr. Vikrant
          Abrol, Founder of the Indian ESG Network. In his address, he
          emphasized India's necessity to assume a leadership role rather than
          simply participating in the worldwide sustainability drive. He also
          unveiled plans for establishing a comprehensive knowledge repository
          and an Online awareness program on ESG. He set the tone for the day
          and emphasized India's unique position in driving the global
          sustainability movement.
          <br />
          <br />
          A truly captivating highlight of the event was the screening of the
          video, "5 Sustainable Practices in Ancient India." which shared
          ancient Indian practices, offering insights into our rich heritage of
          harmonious coexistence with nature.
          <br />
          <br />
          CA Puneeta Puri, Executive Director of the Indian ESG Network, graced
          the occasion with her expertise, delving into the intricacies of the
          ESG readiness & approach. She emphasized the crucial role of a
          well-aligned mindset in facilitating successful ESG implementation
          within organizations. Her insights paved the way for a more refined
          comprehension of the journey toward a sustainable future.
          <br />
          <br />
          The day unfolded with a series of informative and enthralling
          sessions, helmed by experts in their respective domains. These
          sessions encompassed key areas such as Environmental and
          Sustainability considerations, Social Aspects of ESG, Governance as a
          key foundation for ESG, and the intricacies of ESG Reporting & BRSR
          (Business Responsibility and Sustainability Reporting) Disclosure.
          <br />
          <br />
          The collaborative learning environment was enriched by the presence of
          domain experts, including Prof. Purnamita Dasgupta, Chair of
          Environmental Economics and Head of the Environmental and Resource
          Economics Unit at the Institute of Economic Growth; Mr. Sachin
          Golwalkar, CEO of United Way Delhi; Mr. Dinesh Agrawal, Principal
          Consultant at Consocia Advisory; and Mr. Mohan Soni, Founder Partner
          of TAMS & CO LLP, along with like-minded individuals who actively took
          part.
          <br />
          <br />
          The success of the event was amplified by the enthusiastic engagement
          of attendees from diverse backgrounds. The Orientation Program
          observed proactive participation from participants spanning from
          various sectors, as well as special invitees and guests.
          <br />
          <br />
          The Indian ESG Network derives immense satisfaction from the program's
          achievements, reaffirming its unwavering commitment to fostering a
          shared understanding ecosystem. The success of the event serves to
          energize the network's overarching mission, propelling it to become a
          catalyst for positive transformation. This momentum is a step towards
          building a sustainable future for India and the world community.
        </div>
      </div>
      <div className=""></div>
    </>
  )
}
